import styled from "styled-components"

export const RelativeWrapper = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
`

export const AbsoluteWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
`

