import {ContactForm, ContentPhoto, FullPhoto, Header, Hero, Layout, Navigation, TextBox, WhatWeDid,} from "components/CaseLayout";
import SEO                                                                                           from "components/seo";
import React                                                                                         from "react";
import {useCaseData}                                                                                 from "shared/Hooks/CasesNew/useCaseData";
import {FUNDACJA}                                                                                    from "shared/Types/cases";
import BlobsLayout                                                                                   from "../../components/BlobsLayout/BlobsLayout";
import fundacjaBlobs                                                                                 from "../../data/cases/fundacjaBlobs";

const Fundacja = () => {
  const data = useCaseData(FUNDACJA);

  return (
    <Layout color={data.color}>
      <SEO title={data.title} description={data.seo} image={data.opengraph}/>
      <Hero data={data.hero}/>
      <WhatWeDid data={data.what_we_did}/>
      <Navigation type={FUNDACJA}/>
      <FullPhoto data={data.full_images.first}/>
      <BlobsLayout data={fundacjaBlobs.first}>
        <FullPhoto data={data.full_images.second}/>
        <Header data={data.headers.first}/>
        <ContentPhoto color={data.color} data={data.content_images.first}/>
      </BlobsLayout>
      <TextBox data={data.text_boxes.first}/>
      <FullPhoto data={data.full_images.third}/>
      <BlobsLayout data={fundacjaBlobs.second}>
        <Header color="#6121B2" data={data.headers.second}/>
        <ContentPhoto color="#6121B2" data={data.content_images.second}/>
      </BlobsLayout>
      <TextBox data={data.text_boxes.second}/>
      <ContentPhoto color={data.color} data={data.content_images.third}/>
      <BlobsLayout data={fundacjaBlobs.third}>
        <Header color="#6121B2" data={data.headers.third}/>
        <ContentPhoto color="#6121B2" data={data.content_images.fourth}/>
      </BlobsLayout>
      <TextBox data={data.text_boxes.third}/>
      <BlobsLayout data={fundacjaBlobs.fourth}>
        <ContentPhoto data={data.content_images.fifth}/>
      </BlobsLayout>
      <BlobsLayout data={fundacjaBlobs.fifth}>
        <Header color="#6121B2" data={data.headers.fourth}/>
        <ContentPhoto color="#6121B2" data={data.content_images.sixth}/>
      </BlobsLayout>
      <TextBox backgrund="#6121B2" color="#fff" data={data.text_boxes.fourth}/>
      <ContentPhoto color="#6121B2" data={data.content_images.seventh}/>
      <BlobsLayout data={fundacjaBlobs.sixth}>
        <ContentPhoto color="#6121B2" data={data.content_images.eighth}/>
      </BlobsLayout>
      <FullPhoto data={data.full_images.fourth}/>
      <TextBox backgrund="#6121B2" color="#fff" data={data.text_boxes.fifth}/>
      <BlobsLayout data={fundacjaBlobs.seventh}>
        <ContentPhoto color="#6121B2" data={data.content_images.ninth}/>
      </BlobsLayout>
      <TextBox data={data.text_boxes.sixth}/>
      <BlobsLayout data={fundacjaBlobs.eighth}>
        <ContentPhoto data={data.content_images.tenth}/>
      </BlobsLayout>
      <FullPhoto data={data.full_images.fifth}/>
      <ContactForm/>
    </Layout>
  );
};

export default Fundacja;
