import React from 'react';
import Blob from "../Animations/Blob/Blob";
import Rectangle from "../Animations/Rectangle/Rectangle";
import Svg from "../Animations/Svg/Svg";

const Selector = ({element, globalType}) => {

  if (globalType === 'svg') {
    return <Svg element={element}/>
  } else {

    switch (element.type) {
      case 'blob':
        return <Blob element={element}></Blob>
      case 'rectangle':
        return <Rectangle element={element}/>
      default:
        return <></>
    }
  }
};

export default Selector;
