import styled, {keyframes} from "styled-components";
import {Parallax} from "react-scroll-parallax";

const circle = keyframes`
  from { transform: rotate(0deg) translateX(3px) rotate(0deg); }
     to   { transform: rotate(360deg) translateX(3px) rotate(-360deg); }
 `
export const ParallaxContainer = styled(Parallax)`
    position: absolute;
    top: ${({top}) => top};
    left: ${({left}) => left};
    z-index: 2;
    animation: ${circle} ${({time}) => (time ? time : "8s")} linear infinite;
    
    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        display: none;
    }
`

export const Container = styled.div`
    width: ${({width}) => `${width}px`};
    height: ${({height}) => `${height}px`};
    transform: ${({rotation}) => `rotateZ(${rotation})`};
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_big}) {
        width: ${({width}) => `${Math.ceil(width / 1.2)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.2)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: ${({width}) => `${Math.ceil(width / 1.33)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.33)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        width: ${({width}) => `${Math.ceil(width / 1.5)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.5)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        width: ${({width}) => `${Math.ceil(width / 1.74)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.74)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: ${({width}) => `${Math.ceil(width / 2)}px`};
        height: ${({height}) => `${Math.ceil(height / 2)}px`};
    }
`


export const Img = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`
