import React from 'react';
import {Body, Container, ParallaxContainer} from "./styles";

const isBrowser = typeof window !== 'undefined';
let windowWidth = 0;
if (isBrowser) {
  windowWidth = window.innerWidth;
}

const parallaxY = ["-100px", "100px"];
const Rectangle = ({element}) => {
  const {top, left, color, width, height, time = "10s", rotation = '0deg', reverse = false} = element;
  return (
    <ParallaxContainer top={top} left={left} disabled={windowWidth < 1101} y={parallaxY}>
      <Container width={width} height={height}>
        <Body time={time} rotation={rotation} color={color} reverse={reverse}/>
      </Container>
    </ParallaxContainer>
  );
}

export default Rectangle;
