import React from 'react';
import {AbsoluteWrapper, RelativeWrapper} from "./styles";
import Selector from "./Selector/Selector";
import uuid from "react-uuid";

const BlobsLayout = ({children, data}) => {
  return (
    <RelativeWrapper>
      <AbsoluteWrapper>
        {data.items.map(el => <Selector key={uuid()} element={el} globalType={data.type}/>)}
      </AbsoluteWrapper>
      {children}
    </RelativeWrapper>
  );
}

export default BlobsLayout;
