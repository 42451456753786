import React from 'react';
import {Container, Img, ParallaxContainer} from "./styles";

const isBrowser = typeof window !== 'undefined';
let windowWidth = 0;
if (isBrowser) {
  windowWidth = window.innerWidth;
}

const Svg = ({element}) => {
  const {top, left, width, height, src, rotation = '0deg', parallaxY = "0", time} = element;
  const parallaxYArr = [`-${parallaxY}px`, `${parallaxY}px`]
  return (
    <ParallaxContainer top={top} left={left} disabled={windowWidth < 1101} y={parallaxYArr} time={time}>
    <Container width={width} height={height} rotation={rotation}>
        <Img src={src} alt=""/>
    </Container>
    </ParallaxContainer>

  );
}

export default Svg;
