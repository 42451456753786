import Circle    from "assets/images/cases/fundacja/circle.svg"
import Cross     from "assets/images/cases/fundacja/cross.svg"
import Pipe      from "assets/images/cases/fundacja/pipe.svg"
import BigCircle from "../../assets/images/cases/fundacja/big-circle.svg"
import BigCross  from "../../assets/images/cases/fundacja/big-cross.svg"
import BigPipe   from "../../assets/images/cases/fundacja/big-pipe.svg"

const fundacjaBlobs = {
  first:   {
    type: "svg",
    items:
          [
            {
              top:       '0%',
              left:      "90%",
              height:    '485',
              width:     '430',
              src:       BigPipe,
              rotation:  "0deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:    '45%',
              left:   "10%",
              height: '39',
              width:  '39',
              src:    Cross,
              time:   "6s",
            }, {
            top:      '40%',
            left:     "80%",
            height:   '49',
            width:    '43',
            src:      Pipe,
            rotation: "45deg",
            time:     "8s",
          }, {
            top:    '79%',
            left:   "60%",
            height: '46',
            width:  '46',
            src:    Circle,
            time:   "9s",
          }, {
            top:      '85%',
            left:     "22%",
            height:   '49',
            width:    '43',
            src:      Pipe,
            rotation: "90deg",
            time:     "10s",
          },
            {
              top:       '55%',
              left:      "-150px",
              height:    '389',
              width:     '389',
              src:       BigCross,
              rotation:  "0deg",
              parallaxY: "100",
              time:      "0s",
            }
          ]
  },
  second:  {
    type: "svg",
    items:
          [
            {
              top:       '80%',
              left:      "-240px",
              height:    '485',
              width:     '430',
              src:       BigPipe,
              rotation:  "0deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:    '10%',
              left:   "10%",
              height: '39',
              width:  '39',
              src:    Cross,
              time:   "6s",
            }, {
            top:      '20%',
            left:     "80%",
            height:   '49',
            width:    '43',
            src:      Pipe,
            rotation: "70deg",
            time:     "9s",
          }, {
            top:    '40%',
            left:   "70%",
            height: '46',
            width:  '46',
            src:    Cross,
            time:   "6s",
          },
          ]
  },
  third:   {
    type: "svg",
    items:
          [
            {
              top:       '10%',
              left:      "90%",
              height:    '389',
              width:     '389',
              src:       BigCross,
              rotation:  "45deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:      '10%',
              left:     "10%",
              height:   '39',
              width:    '39',
              rotation: "45deg",
              src:      Cross,
              time:     "6s",
            }, {
            top:      '80%',
            left:     "25%",
            height:   '49',
            width:    '43',
            src:      Pipe,
            rotation: "30deg",
            time:     "7s",
          }, {
            top:      '40%',
            left:     "70%",
            height:   '46',
            width:    '46',
            rotation: "90deg",
            src:      Cross,
            time:     "8s",
          },
          ]
  },
  fourth:  {
    type: "svg",
    items:
          [
            {
              top:       '140px',
              left:      "-200px",
              height:    '389',
              width:     '389',
              src:       BigCircle,
              rotation:  "45deg",
              parallaxY: "100",
              time:      "0s",
            },
          ]
  },
  fifth:   {
    type: "svg",
    items:
          [
            {
              top:       '30%',
              left:      "-150px",
              height:    '389',
              width:     '389',
              src:       BigCross,
              rotation:  "90deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:      '10%',
              left:     "10%",
              height:   '39',
              width:    '39',
              rotation: "10deg",
              src:      Cross,
              time:     "8s",
            }, {
            top:      '30%',
            left:     "79%",
            height:   '49',
            width:    '43',
            src:      Pipe,
            rotation: "30deg",
            time:     "9s",
          },
            {
              top:       '70%',
              left:      "90%",
              height:    '389',
              width:     '389',
              src:       BigPipe,
              rotation:  "20deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:      '70%',
              left:     "10%",
              height:   '46',
              width:    '46',
              rotation: "90deg",
              src:      Cross,
              time:     "8s",
            }, {
            top:      '98%',
            left:     "80%",
            height:   '46',
            width:    '46',
            rotation: "20deg",
            src:      Cross,
            time:     "6s",
          },
          ]
  },
  sixth:   {
    type: "svg",
    items:
          [
            {
              top:       '20%',
              left:      "89%",
              height:    '389',
              width:     '389',
              src:       BigCross,
              rotation:  "0deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:       '70%',
              left:      "-180px",
              height:    '389',
              width:     '389',
              src:       BigPipe,
              rotation:  "20deg",
              parallaxY: "100",
              time:      "0s",
            },
          ]
  },
  seventh: {
    type: "svg",
    items:
          [
            {
              top:       '20%',
              left:      "10%",
              height:    '40',
              width:     '40',
              src:       Cross,
              rotation:  "20deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:       '30%',
              left:      "85%",
              height:    '40',
              width:     '40',
              src:       Pipe,
              rotation:  "-10deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:       '90%',
              left:      "85%",
              height:    '40',
              width:     '40',
              src:       Cross,
              rotation:  "0deg",
              parallaxY: "100",
              time:      "0s",
            },
          ]
  },
  eighth:   {
    type: "svg",
    items:
          [
            {
              top:       '60%',
              left:      "90%",
              height:    '389',
              width:     '389',
              src:       BigPipe,
              rotation:  "20deg",
              parallaxY: "100",
              time:      "0s",
            },
            {
              top:       '20%',
              left:      "-180px",
              height:    '389',
              width:     '389',
              src:       BigCross,
              rotation:  "20deg",
              parallaxY: "100",
              time:      "0s",
            },
          ]
  },

}

export default fundacjaBlobs
