import styled from "styled-components";
import {Parallax} from "react-scroll-parallax";

export const ParallaxContainer = styled(Parallax)`
    position: absolute;
    top: ${({top}) => top};
    left: ${({left}) => left};
    z-index: 10;

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        display: none;
    }
    
`
export const Container = styled.div`
    width: ${({width}) => `${width}px`};
    height: ${({height}) => `${height}px`};
    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_big}) {
        width: ${({width}) => `${Math.ceil(width / 1.2)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.2)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop}) {
        width: ${({width}) => `${Math.ceil(width / 1.33)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.33)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.laptop_small}) {
        width: ${({width}) => `${Math.ceil(width / 1.5)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.5)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.tablet_big}) {
        width: ${({width}) => `${Math.ceil(width / 1.74)}px`};
        height: ${({height}) => `${Math.ceil(height / 1.74)}px`};
    }

    @media screen and (max-width: ${({theme}) => theme.breakpoints.phone}) {
        width: ${({width}) => `${Math.ceil(width / 2)}px`};
        height: ${({height}) => `${Math.ceil(height / 2)}px`};
    }

`

export const Body = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 15%;
    position: relative;
    transform: ${({rotation}) => (rotation ? `rotate(${rotation})` : "")};
    background-color: ${({color}) => color};
`
