import React from 'react';
import {Body, Container, ParallaxContainer} from "./styles";

const isBrowser = typeof window !== 'undefined';
let windowWidth = 0;
if (isBrowser) {
  windowWidth = window.innerWidth;
}

const Blob = ({element}) => {
  const {top, left, color, width, height, time = '3s', rotation = '0deg', parallaxY = "100"} = element;
  const parallaxYArr = [`-${parallaxY}px`, `${parallaxY}px`]

  return (
    <ParallaxContainer top={top} left={left} disabled={windowWidth < 1101} y={parallaxYArr}>
      <Container width={width} height={height}>
        <Body color={color} rotation={rotation} time={time}/>
      </Container>
    </ParallaxContainer>
  );
}

export default Blob;
